<template>
  <div class="hoho-page">
    <div class="hoho-container">
      <el-alert title="请务必正确填写商品分类" type="warning" show-icon>
      </el-alert>
      <div class="hoho-form-container">
        <el-card>
          <el-form
            label-width="120px"
            :model="modelForm"
            :rules="rules"
            ref="modelForm"
          >
            <div class="line">
              <div class="line-cell engoodsname">
                <el-form-item label="商品中文分类" prop="categoryCn">
                  <el-input
                    v-model.trim="modelForm.categoryCn"
                    maxlength="20"
                    placeholder="输入商品中文分类"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell engoodsname">
                <el-form-item label="中文成分">
                  <el-input
                    v-model.trim="modelForm.ingredientsCn"
                    maxlength="20"
                    placeholder="请输入中文成分"
                    show-word-limit
                    @blur="translateEn"
                  >
                  </el-input
                ></el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell engoodsname">
                <el-form-item label="英文成分">
                  <el-input
                    v-model.trim="modelForm.ingredientsEn"
                    maxlength="20"
                    placeholder="请输入英文成分"
                    show-word-limit
                  >
                  </el-input
                ></el-form-item>
              </div>
            </div>
          </el-form>
        </el-card>
      </div>
    </div>
    <div class="hoho-bottom-buts" v-if="!modelForm.id">
      <el-button type="primary" @click="subimtForm">提交分类</el-button>
    </div>
  </div>
</template>

<script>
import { vxRule } from '../../utils/validator'
import Category from '../../api/category'
import Common from '../../api/common'
export default {
  components: {},
  name: '',
  data() {
    return {
      labelPosition: 'right',
      modelForm: {
        guid: '', // 分类ID
        categoryCn: '', // 分类名称
        ingredientsCn: '', // 中文成分
        ingredientsEn: '' // 英文成分
      },
      rules: {
        categoryCn: vxRule(true, null, 'blur', '请输入商品中文分类')
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    if (this.$route.query.guid) {
      this.modelForm.guid = this.$route.query.guid
      this.getDetailByGuid(this.modelForm.guid)
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    translateEn(){
      if(this.modelForm.ingredientsCn){
      Common.translate({ content: this.modelForm.ingredientsCn, language: 'en' }, false).then(
          (res) => {
            if (res.state) {
              this.modelForm.ingredientsEn = res.data
            }
          }
        )
      }
    },
    // 通过商品编码获取商品
    getDetailByGuid(guid) {
      Category.detailByGuid({ guid }).then((res) => {
        if (res.state) {
          this.modelForm.guid = res.data.guid
          this.modelForm.categoryCn = res.data.categoryCn
          this.modelForm.ingredientsCn = res.data.ingredientsCn
          this.modelForm.ingredientsEn = res.data.ingredientsEn
        }
      })
    },
    // 提交商品
    subimtForm() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          if (this.modelForm.guid) {
            Category.edit({
              guid: this.modelForm.guid,
              categoryCn: this.modelForm.categoryCn,
              ingredientsCn: this.modelForm.ingredientsCn,
              ingredientsEn: this.modelForm.ingredientsEn
            }).then((res) => {
              console.log(res)
              if (res.state) {
                this.$message.success('提交成功')
                // this.$refs.modelForm.resetFields()
                // this.this.modelForm.guid = ''
                // this.modelForm.categoryCn = ''
                // this.modelForm.ingredientsCn = ''
                // this.modelForm.ingredientsEn = ''
              }
            })
          } else {
            Category.add({
              categoryCn: this.modelForm.categoryCn,
              ingredientsCn: this.modelForm.ingredientsCn,
              ingredientsEn: this.modelForm.ingredientsEn
            }).then((res) => {
              console.log(res)
              if (res.state) {
                this.$message.success('提交成功')
                this.$refs.modelForm.resetFields()
                this.modelForm.categoryCn = ''
                this.modelForm.ingredientsCn = ''
                this.modelForm.ingredientsEn = ''
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hoho-page {
  height: 100vh;
  overflow: hidden;
}
.hoho-container {
  overflow-y: auto;
  padding: 10px;
}
.common-explain {
  display: flex;
  align-items: center;
  color: rgb(229, 161, 71);
  margin-bottom: 5px;
  font-size: 12px;
  .iconfont {
    margin-right: 2px;
    font-size: 14px;
  }
}
.common-explain.nobott {
  margin-bottom: 0;
}
.line {
  display: flex;
  align-content: center;
  height: 58px;
  .qrcode {
    height: 36px;
  }
  .line-cell.goodscode {
    width: 340px;
  }
  .line-cell.qrcode {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .explain {
      font-size: 10px;
      transform: scale(0.8);
      margin-top: -2px;
    }
  }
  .line-cell.nospace {
    ::v-deep {
      .el-form-item__content {
        margin-left: 10px !important;
      }
    }
  }
  .line-cell.goodsname {
    width: 470px;
    padding-top: 5px;
    ::v-deep {
      .el-select {
        width: 100% !important;
      }
    }
  }
  .line-translate {
    height: 41px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .line-cell.translate {
    width: 490px;
    background: rgb(253, 246, 237);
    padding-right: 20px;
    padding-top: 5px;
    margin-left: 10px;
  }
  .line-cell.goodsattr {
    width: 400px;
  }
  .upload {
    display: flex;
    margin: 22px 0;
    .title {
      width: 110px;
      text-align: right;
      margin-right: 10px;
      color: rgb(96, 98, 102);
    }
  }
  .line-cell.engoodsname {
    width: 480px;
  }
}
.line.auto-height {
  height: auto;
}
</style>
